<template>
  <div class="bg-white text-infos">
    <b-row>
      <b-col cols="6">{{ $t("tours.list.start_date") }}</b-col>
      <b-col cols="6">
        {{ currentTour.startDate | datetime("dd/MM/yyyy", "fr") }}
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">{{ $t("tours.details.end_date") }}</b-col>
      <b-col cols="6">
        {{ currentTour.endDate | datetime("dd/MM/yyyy", "fr") }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentTour: "tour/currentEntry",
    }),
  }
};
</script>

<style lang="scss" scoped></style>
